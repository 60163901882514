import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Wir erstellen den Safe für Ihr Daten-Vermögen!`}</p>
    <p>{`Safes schützen heutzutage nicht nur Geld, sondern auch Datenbanken. Wir sichern Ihre Produktions- und Verwaltungsdaten.`}</p>
    <h2>{`Wir bieten:`}</h2>
    <ul>
      <li parentName="ul">{`Sicherung von Authentizität, Integrität, Vertraulichkeit und Verbindlichkeit Ihrer Daten`}</li>
      <li parentName="ul">{`Konzeption, Design, Implementierung und Pflege komplexer und sicherer Datenbanklösungen`}</li>
      <li parentName="ul">{`effiziente Datenmodellierung`}</li>
      <li parentName="ul">{`widerspruchsfreie und leistungsfähige Datenverwaltung`}</li>
      <li parentName="ul">{`Entwicklung anspruchsvoller und vielschichtiger Benutzeroberflächen`}</li>
      <li parentName="ul">{`Vertrauliche Übertragung an entfernte Systeme`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      